import React, {useState} from "react";
import './Landing.css';
import { useAuth } from '../../context/AuthContext';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import qr from '../../assets/qr.jpg';
import card from '../../assets/card.png';
import console from '../../assets/console.png';
import prevideo from '../../assets/pre_mobile.png';
import wa from '../../assets/wa.png';


const Landing = () => {
    const { contact } = useAuth();
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            const response = await contact(formData.identifier, formData.message);          
            //const data = await response.json();
            setSuccess(response.message);
        } catch (error) {
            setSuccess(error.message || 'Fehler beim senden der Nachricht');
        }
    }

    const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value
        });
    };

    const [formData, setFormData] = useState({
          identifier: '',
          message: ''
        });

    const [success, setSuccess] = useState('');

    return (
        <div>
            <section className="landing-masthead">
                <Container className="landing-h-100">
                    <Row className="landing-h-100">
                        <Col lg={7} className="landing-my-auto">
                            <Row>
                                <div className="landing-mx-auto">
                                    <h1>VIRTUELLE FIGHTCARDS</h1>
                                    <h3>Das nächste Level für Ihre Kampfsportveranstaltung</h3>
                                </div>
                            </Row>
                            <Row>
                                <div className="landing-mx-auto">   
                                    <a href="#contact">                    
                                        <svg 
                                            width="100" 
                                            height="100"
                                            stroke="white" 
                                            strokeWidth="3"
                                            strokeLinecap="round"
                                            strokeLinejoin="round">
                                            
                                            <polyline 
                                                points="20,30 20,70 80,70 80,30 20,30 50,50 80,30" 
                                                fill="#7b4397" />
                                        </svg>
                                    </a>
                                </div>
                            </Row>
                            <Row>
                                <div className="landing-mx-auto landing-wa">   
                                    <a href="https://wa.me/4915129411243">                    
                                        <img src={wa} alt="WhatsApp Link"/>
                                    </a>
                                </div>
                            </Row>
                        </Col>
                        <Col lg={5} className="landing-masthead-right">
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="landing-section-info">
                <Container className="landing-h-100">
                    <Row >
                        <Col md={4} className="landing-mx-auto">
                            <h2>Flexibel</h2>
                            <p>Der Vorteil der virtuellen Fightcards (vFightcards) liegt auf der Hand: Sie können bis zum Veranstaltungsabend flexibel auf Ausf&auml;lle oder &Auml;nderungen reagieren. F&auml;llt ein K&auml;mpfer oder K&auml;mpferin aus, k&ouml;nnen Sie die Fight Card sofort anpassen und Ihrem Publikum die aktualisierte Fight Card pr&auml;sentieren.</p>    
                        </Col>
                        <Col md={4} className="landing-mx-auto">
                        <h2>Sponsoren</h2>
                            <p>Gewinnen Sie Sponsoren! Sponsoren werden nicht nur einfach in einem Prospekt abgedruckt. Die Sponsoren werden den K&auml;mpfen auf den vFightcards zugeordnet. Sie können Ihre Sponsoren direkt verlinken, so dass der Besucher den Webauftritt besuchen kann und sich so ein genaueren Einblick in das Angebot des Sponsors verschaffen kann.</p>    
                        </Col>
                        <Col md={4} className="landing-mx-auto">
                            <h2>Werbung</h2>
                            <p>Machen Sie in sozialen Medien bereits Monate im Voraus Werbung mit Ihrer Kampfsportveranstaltung. Eine vorgeschaltete Landingpage informiert über Veranstaltungsort und Zeitpunkt. Selbstverst&auml;ndlich werden hier bereits Ihre Sponsoren pr&auml;sentiert. Ihre vFightcards k&ouml;nnen Sie freigeben, sobald Sie damit zufrieden sind.</p>    
                        </Col>
                    </Row>
                    <hr></hr>
                    <Row >
                        <Col md={8} className="landing-mx-auto">
                            <h2>Geben Sie Ihren Sponsoren einen angemessenen Platz</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8} className="landing-mx-auto">
                            <img src={card} alt="fightcard" class="landing-section-image"></img>
                        </Col>
                    </Row>
                    <hr></hr>
                    <Row >
                        <Col md={8} className="landing-mx-auto">
                            <h2>Einfacher Zugang für Ihre Besucher</h2>
                            <p>Ihre Besucher können die Fightcard über einen QR-Code aufrufen. Es muss keine App installiert werden, die Darstellung erfolgt im Browser. Den QR Code können Sie bereits auf die Tickets drucken oder präsentieren den Code auf Klappaufstellern.  </p>
                            <p>Probieren Sie es aus!</p>
                        </Col>
                    </Row>
                    <Row className="">
                        <Col md={8} className="landing-mx-auto">
                            <img src={qr} alt="fightcard" class="landing-section-image"></img>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="landing-submasthead">
                <Container className="landing-h-100">
                    <Row className="landing-h-100">
                        <Col lg={12} className="landing-submasthead-right">
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="landing-section-features">
                <Container className="landing-h-100">
                    <Row >
                        <Col md={8} className="landing-mx-auto">
                            <h2>Erstellen und bearbeiten Sie die Fightcard bequem über Ihren Webbrowser</h2>
                            <p>Über ihren persönlichen Zugang können Sie die Fightcards einfach zusammenstellen. Sollte sich etwas ändern, könnnen die Fightcards jederzeit angepasst-, oder die Reihenfolge geändert werden. Sogar noch am Veranstaltungsabend.</p>
                        </Col>
                    </Row>
                    <Row className="">
                        <Col md={8} className="landing-mx-auto">
                            <img src={console} alt="Webkonsole" class="landing-section-image"></img>
                        </Col>
                    </Row>
                    <hr></hr>
                </Container>
            </section> 
            <section className="landing-section-mobile">
                <Container className="landing-h-100">
                    <Row className="">
                        <Col md={6} className="landing-mt-xl">
                            <h3>Auf einen Blick</h3>
                            <ul>
                                <li>Keine Ausdrucke</li>
                                <li>Einfacher Zugang über QR-Code</li>
                                <li>Sie können mit der Werbung bereits Monate im Vorraus beginnen</li>
                                <li>Fightcards werden erst nach Freigabe in der Konsole angezeigt</li>
                                <li>Kämpfe jederzeit änderbar</li>
                                <li>Kinderleichte Bearbeitung über Webkonsole</li>
                                <li>Verlinkte Sponsoren</li>
                                <li>Kämpferdetails mit einem Klick auf die Karte einsehbar</li>
                                <li>Zeigen Sie Ihren Besuchern wann Pausen geplant sind</li>
                            </ul>
                        </Col>
                        <Col md={2} className="landing-mx-auto landing-mt-xl">
                            <video max-width="360" poster={prevideo} className="landing-video" autoPlay muted loop>
                                    <source src="/Video/fightcards_mobile_portrait.mp4" type="video/mp4"/>
                                    <img src={prevideo} id="imgPhoneVertical" alt="Fight Card Mobil Portrait"/>
                            </video>
                        </Col>                  
                    </Row>
                    <Row className="landing-mx-auto landing-mt-xl">
                        <h2>Kostenlose Nutzung der Fightcards für das Jahr 2025!</h2>
                    </Row>
                    <Row>
                        <div className="landing-mx-auto landing-mt-xl">
                            <a href= "mailto:post@fightcards.de" className="landing-mail">post@fightcards.de</a>
                        </div>
                    </Row>
                </Container>
            </section>
            <section className="landing-section-contact" id="contact">
                <Container className="landing-h-100">
                    <Row>
                        <h3>Kontakt</h3>
                        <Col md={8} className="landing-mx-auto">
                            {success && (
                                <div className="login-modal-success">
                                    {success}
                                </div>
                            )}
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
            
                                    <Form.Control 
                                        type="email" 
                                        placeholder="Enter email" 
                                        
                                        name="identifier"
                                        value={formData.identifier}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Nachricht</Form.Label>
                                    <Form.Control 
                                        as="textarea" 
                                        placeholder="Hinterlassen Sie Ihr Nachricht oder eine Telefonnummer ich melde mich umgehend bei Ihnen"
                                        
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        rows={4}
                                    />
                                </Form.Group>
                                
                                <Button variant="primary" type="submit">
                                    Absenden
                                </Button><br/>

                                <Form.Text className="text-muted">
                                    Nachrichten und Email Adressen werden nicht gespeichert und dienen nur zur Kontaktaufnahme. 
                                </Form.Text>
                            </Form>
                        </Col>
                    </Row>
                </Container>               
            </section>
        </div>
        
    );
}


export default Landing;